import { Injectable } from '@angular/core';
import { User } from '../model/user.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  public user: User;

  constructor(private api:ApiService) { }

  logIn(email:string,password:string){
    this.api.logIn(email,password);
  }

  isLoggedIn(): boolean{
    return !!this.user;
  }
  logOut(){
    
  }
}
