import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '../app.constants';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http:HttpClient) { }

  logIn(email:string,password:string){
    const body = {
      email: email,
      password: password
    };
    this.http.post(`${API}/users/login.php`,body).subscribe((resp)=>{
      console.log("resp",resp);
    },(err)=>{
      console.log("err",err);
    })
  }
}
