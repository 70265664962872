import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { AuthorizationService } from '../../services/authorization.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  
    public form: FormGroup;
  
    public emailError:string = null;
    public passwordError:string = null;
    public generalError:string = null;
  
    public waiting:boolean=false;
  
    constructor( public authService: AuthorizationService,
                 private translate: TranslateService, ) {
      this.form = new FormGroup({
        'email': new FormControl(''),
        'password': new FormControl('')
      })
    }
  
    resetErrors(){
      this.emailError = null;
      this.passwordError = null;
      this.generalError = null;
    }
    
    validateEmail(email:string) {
        let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return regexp.test(String(email).toLowerCase());
    }
  
    submit(){
      let error:boolean = false;
      let email:string = this.form.controls['email'].value;
      if( !this.validateEmail(email) ){
        error = true;
        this.emailError="L'email és invàlid";
      }
      if( email == ""){
        error = true;
        this.emailError="El camp és necessari";
      }      
      let password:string = this.form.controls['password'].value;
      if(password == ""){
        error = true;
        this.passwordError="El camp és necessari";
      }
      if(!error){
        this.authService.logIn(email,password);
      }      
    }
  }
  