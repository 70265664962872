import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LANGUAGES } from './app.constants';
import { AuthorizationService } from './services/authorization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Viu el Pàdel';

  constructor(private router: Router,
              private translate: TranslateService,
              public auth: AuthorizationService,
              private cookieService: CookieService){
      this.setInitialLanguage();
      this.changeLanguage = this.changeLanguage.bind(this);
  }
  
  setInitialLanguage(){
    let currentLang:string = this.cookieService.get('dvt-current-lang');
    if ( !currentLang || LANGUAGES.includes(currentLang) ) {
      currentLang = LANGUAGES[0];
    }
    this.translate.setDefaultLang(currentLang);
    document.getElementsByTagName('html')[0].setAttribute('lang',currentLang);
  }

  changeLanguage(lang:string){
    if (LANGUAGES.includes(lang)){
      this.cookieService.set( 'dvt-current-lang' , lang , 30 );
      this.translate.use(lang);
      document.getElementsByTagName('html')[0].setAttribute('lang',lang);
    }
  }
}
